<template>
    <a-modal type="primary" visible title="Transport Request" :onCancel="handleCancel" okText="Send Mail" @ok="onSubmit"
        width="50%">
        <div class="project-modal">
            <FormValidationWrap>
                <VerticalFormStyleWrap>
                    <a-form name="sDash_validation-form" ref="formRef" @finish="onSubmit" :model="formState" :rules="rules"
                        :layout="formState.layout">
                        <a-row :gutter="25">
                            <a-col :md="24" :xs="24">
                                <a-form-item ref="subject" label="Subject" name="subject">
                                    <a-input v-model:value="formState.subject" placeholder="Subject" size="large" />
                                </a-form-item>
                            </a-col>
                            <a-col :md="24" :xs="24">
                                <a-form-item label="Logistics Companies" name="partnersIds">
                                    <a-select v-if="partnerApi.length" mode="multiple" v-model:value="formState.partnersIds"
                                        placeholder="Please choose Transport Company" show-search style="width: 100%"
                                        :filter-option="customFilter">
                                        <a-select-option v-for="(partner, index) in partnerApi" :key="index"
                                            :value="partner.id">
                                            {{ partner.name }} ({{ partner.countryName }})</a-select-option>
                                    </a-select>
                                    <a-select v-else placeholder="Please choose Owner">
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :xs="24" :md="24">
                                <a-row justify="space-between">
                                    <a-col :span="11">
                                        <a-form-item label="Incoterm" name="incotermId" ref="incotermId">
                                            <a-select v-model:value="formState.incotermId" placeholder="Incoterm"
                                                show-search style="width: 100%" :options="incotermList"
                                                :filter-option="selectFilterOption" @change="addDeliveryToMessage">
                                            </a-select>
                                        </a-form-item>
                                    </a-col>
                                    <a-col :span="11">
                                        <a-form-item label="Place of Delivery" name="placeOfDelivery" ref="placeOfDelivery">
                                            <a-input v-model:value="formState.placeOfDelivery" size="large"
                                                placeholder="Place of Delivery" @change="addDeliveryToMessage" />
                                        </a-form-item>
                                    </a-col>
                                    <a-col :span="24">
                                        <a-form-item label="Transport Method" name="transportMethodIds"
                                            ref="transportMethodIds">
                                            <a-select v-model:value="formState.transportMethodIds" mode="multiple"
                                                placeholder="Transport Method" show-search style="width: 100%"
                                                :options="transportMethodList" :filter-option="selectFilterOption"
                                                @change="addDeliveryToMessage">
                                            </a-select>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                            </a-col>
                            <a-col :xs="24" :md="24" v-if="SingleProductApi.files?.length">
                                <a-form-item label="Attached files" name="filesIds">
                                    <a-checkbox-group v-model:value="formState.filesIds" style="width: 100%">
                                        <a-row>
                                            <a-col v-for="file in SingleProductApi.files" :key="file.id" :span="24">
                                                <a-checkbox :value="file.id"> <span class="file-text"
                                                        :title="file.fileName">{{ getFileLabel(file.fileType) }}</span>
                                                </a-checkbox>
                                                <a @click="downloadFile(file.id)">
                                                    <sdFeatherIcons type="download" />
                                                </a>
                                            </a-col>
                                        </a-row>
                                    </a-checkbox-group>
                                </a-form-item>
                            </a-col>
                            <a-col :xs="24" :md="24">
                                <div class="sDash_editor">
                                    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
                                </div>
                            </a-col>
                        </a-row>
                    </a-form>
                </VerticalFormStyleWrap>
            </FormValidationWrap>
        </div>
    </a-modal>
</template>

<script setup>
import { ref, onMounted, computed, defineProps, reactive, defineEmits, watch } from 'vue';
import { notification } from "ant-design-vue";
import { useFileDownload } from '@/composable/useFileDownload';
import { DataService } from "@/config/dataService/dataService";
import { useStore } from "vuex";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    FormValidationWrap,
    VerticalFormStyleWrap,
} from "../../forms/overview/Style";

const { downloadFile } = useFileDownload();
const { state, dispatch } = useStore();
const props = defineProps({
    product: String,
});
const emit = defineEmits(['Cancel'])

const partnerApi = computed(() => state.partnerApi.data);
const IncotermApi = computed(() => state.IncotermApi.data);
const TransportMethodApi = computed(() => state.TransportMethodApi.data);
const SingleProductApi = computed(() => state.SingleProductApi.data);
const SizeApi = computed(() => state.SizeApi.data);

const formRef = ref();
const rules = {
    stateOfWorksIds: [
        {
            type: "array",
            required: true,
            message: "Select SOWs",
            trigger: "change",
        },
    ],
    partnersIds: [
        {
            type: "array",
            required: true,
            message: "Select partners",
            trigger: "change",
        },
    ],
    message: [
        {
            required: true,
            message: "Message is required",
            trigger: "change",
        },
    ],
    subject: [
        {
            required: true,
            message: "Subject is required",
            trigger: "change",
        },
    ],
    incotermId: [
        {
            required: true,
            message: "Incoterm is required",
            trigger: "change",
        },
    ],
    transportMethodIds: [
        {
            type: "array",
            required: true,
            message: "Transport Method is required",
            trigger: "change",
        },
    ],
    placeOfDelivery: [
        {
            required: true,
            message: "Place of Delivery is required",
            trigger: "change",
        },
    ],
};
const formState = reactive({
    layout: "vertical",
    productId: props.product.id,
    message: "",
    incotermId: "",
    transportMethodIds: [],
    placeOfDelivery: "",
    subject: `Transport Request(${props.product.dealNumber})`,
    partnersIds: [],
    filesIds: [],
});

const productDiscription = computed(() => SingleProductApi.value.weightsConversion
    ? `${props.product.categoryName} ${props.product.sizeName}, ${props.product.weightName}, ${props.product.gradeName}, ${props.product.threadName}, ${props.product.rangeName}${SingleProductApi.value.weightsConversion ? ', ' + SingleProductApi.value.weightsConversion.meter * 1000 + ' mm' : ""}`
    : props.product.note);
const quantity = computed(() => SingleProductApi.value.weightsConversion ? `${SingleProductApi.value.weightsConversion.meter} meters, ${SingleProductApi.value.weightsConversion.joint} joints, ${SingleProductApi.value.weightsConversion.foot} ft, ${SingleProductApi.value.weightsConversion.tonne} Tonnes` : '');
const Location = computed(() => {
    const components = [];
    if (props.product.countryName)
        components.push(props.product.countryName);

    if (props.product.stockLocationMaps)
        components.push(props.product.stockLocationMaps);

    if (props.product.stockLocation)
        components.push(props.product.stockLocation);

    return components.join(', ');
});

const getFileLabel = (fileType) => {
    switch (fileType) {
        case 1:
            return 'Picture Product'
        case 2:
        case 3:
            return 'Certificate & MTR'
        case 4:
            return 'Inspection Report'
        case 5:
            return 'Supplier Proposal'
    }
};

const editor = ref(ClassicEditor);
const editorData = ref(`<h3>Good Day,</h3>

    <p>Please quote you best offer for shipping cargo as follows:</p>

    <ul>
        <li><strong>Collecting adress:</strong> ${props.product.incotermName} - ${Location.value}</li>
        <li><strong>Cargo:</strong> </li>
        <li><strong>Quantity:</strong> ${quantity.value} </li>
        <li><strong>Number of packages:</strong> </li>
        <li><strong>Dimensions:</strong>  </li>
        <li><strong>Total weight:</strong>  </li>
    </ul>
    <p><strong>Delivery to:</strong> <span></span></p>

    <p>Best regards</p>`);
const editorConfig = ref({
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
});


const updateText = () => {
    editorData.value = editorData.value.replace(/<strong>Quantity:<\/strong>(.*?)<\/li>/gs, `<strong>Quantity:</strong> ${quantity.value}</li>`).replace(/<strong>Cargo:<\/strong>(.*?)<\/li>/gs, `<strong>Cargo:</strong> ${productDiscription.value}</li>`)
        .replace(/<strong>Total weight:<\/strong>(.*?)<\/li>/gs, `<strong>Total weight:</strong> ${SingleProductApi.value?.weightsConversion?.tonne} Tonne</li>`);
};
const updateNumberOfPackage = () => {
    if (SingleProductApi.value?.sizeId) {
        editorData.value = editorData.value.replace(/<strong>Number of packages:<\/strong>(.*?)<\/li>/gs, `<strong>Number of packages:</strong> ${props.product.numberPackage}, ${getCargoSize(SingleProductApi.value?.sizeId)}</li>`)
    } else {
        editorData.value = editorData.value.replace(/<strong>Number of packages:<\/strong>(.*?)<\/li>/gs, `<strong>Number of packages:</strong> ${props.product.numberPackage}</li>`)
    }
};
const addDeliveryToMessage = () => {
    const incoterm = incotermList.value.find(item => item.value == formState.incotermId) ? incotermList.value.find(item => item.value == formState.incotermId).label : "";
    var selectedLabels = [];
    var selectedtransportMethodIds = [];
    if (formState.transportMethodIds?.length) {
        const selectedIds = formState.transportMethodIds;
         selectedtransportMethodIds = transportMethodList.value.filter(
            (method) => selectedIds.includes(method.value)
        );
        selectedLabels = selectedtransportMethodIds.map((method) => method?.label).join(' and ');
    }

    editorData.value = editorData.value.replace(/<strong>Delivery to:<\/strong>(.*?)<\/p>/gs, `<strong>Delivery to:</strong> ${incoterm} - ${formState.placeOfDelivery} - ${selectedtransportMethodIds.length} option(s): ${selectedLabels}</p>`);
};

onMounted(async () => {
    dispatch("sizeInfo");
    dispatch("incotermInfo");
    dispatch("transportMethodInfo");
    dispatch("partnerWithTransportActivityByCountry", props.product.countryId);
    dispatch("getProduct", props.product.id);
});

const incotermList = computed(() => {
    if (IncotermApi.value && IncotermApi.value.length) {
        return IncotermApi.value.map((country, key) => {
            return {
                key: key + 1,
                label: country.name,
                value: country.id,
            };
        });
    } else {
        return [];
    }
});
const transportMethodList = computed(() => {
    if (TransportMethodApi.value && TransportMethodApi.value.length) {
        return TransportMethodApi.value.map((country, key) => {
            return {
                key: key + 1,
                label: country.name,
                value: country.id,
            };
        });
    } else {
        return [];
    }
});


const customFilter = (input, option) => {
    return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const getCargoSize = (sizeId) => {
    const size = SizeApi.value.find(item => item.id == sizeId);
    const sizeInInch = size.mm * 0.0393701;
    return `${size.name} - ${size.mm}mm - ${sizeInInch}inch`;
};

const onSubmit = async () => {
    formRef.value
        .validate()
        .then(async () => {
            try {
                formState.message = editorData.value;
                var response;
                console.log("fform", formState);
                response = await DataService.post("api/TransportRequest", formState);

                if (response) {
                    if (response.succeeded) {
                        notification.success({
                            message: response.message,
                        });
                        emit('cancel')
                    }
                } else {
                    notification.error({
                        message: response.message,
                    });
                }
            } catch (err) {
                notification.error({
                    message: "Error while sending mail",
                });
            }
        })
}
const selectFilterOption = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const handleCancel = () => {
    emit('cancel')
};

watch(
    () => SingleProductApi.value,
    () => {
        // The function to execute when the quantity changes
        updateText();
    }
);
watch(
    () => SingleProductApi.value || SizeApi.value,
    () => {
        // The function to execute when the quantity changes
        updateNumberOfPackage();
    }
);
</script>
